<template>
  <div class="layout-c">
    <VisualizationHeader />
    <Visualization />
    <VisualizationFooter />
  </div>
</template>

<script>
import Visualization from '@/components/Visualization';
import VisualizationFooter from '@/components/VisualizationFooter';
import VisualizationHeader from '@/components/VisualizationHeader';

export default {
  name: 'C',
  components: {
    Visualization,
    VisualizationFooter,
    VisualizationHeader,
  },
};
</script>
